@import 'mixins';

$defaultFont: 'Open Sans', sans-serif;
$menuFont: 'Yanone Kaffeesatz', sans-serif;
$menuColor: #000000;
$textColor: #333;

/*primary*/
$buttonTextColor: #fff;
$buttonColor: #235a7e;
$buttonBorderColor: #235a7e;
 
/*default*/
$buttonDefaultTextColor: #333;
$buttonDefaultColor: #fff;
$buttonDefaultBorderColor: #ccc;

$searchColor: #c1c2c0;
$searchMobileColor: #434244;

/*checkout*/
$backgroundCheckout: #f5f5f5;

/*trash*/
$buttonTrashColor: #235a7e;

$newAttributeBgColor: #ec6c56;


.DoNotShowPrices {
    z-index: 55;
    position: absolute;
}

.HeaderLogo {
    background-image: url(Images/logo.png);
    width: 200px;

    @include fromSizeMD() {
        width: 340px;
        height: 50px;
        margin-top: 5px;
    }
}


    .btn-primary {
        color: $buttonTextColor;
        background-color: $buttonColor;
        border-color: $buttonBorderColor;

        &:hover {
            background-color: lighten($buttonColor, 5%);
            border-color: $buttonBorderColor;
        }
    }

    .btn-default {
        color: $buttonDefaultTextColor;
        background-color: $buttonDefaultColor;
        border-color: $buttonDefaultBorderColor;
    }

    .btn:focus,
    .btn:active:focus,
    .btn.active:focus,
    .btn.focus,
    .btn:active.focus,
    .btn.active.focus {
        outline: 0px;
    }

.menu-container {
    padding-top: 43px;
    background: $buttonColor;

    @include fromSizeSM() {
        padding-top: 0;
        background: $menuColor;
    }

    @include fromSizeMD() {
        border-bottom: 20px solid $buttonColor;
    }
}

    .SubMenuBox,
    .navbar-default .navbar-nav > li > a {
        &:hover,
        &:focus,
        &:active {
            background: lighten($menuColor, 10%);
        }
    }

    .navbar-nav > li:first-child > a {
        @include fromSizeMD() {
            padding-left: 15px;
        }
    }

    .navbar-nav > li:last-child > a {
        @include fromSizeMD() {
            padding-right: 15px;
        }
    }

    .InfoMenu {
        color: $menuColor;
    }

    .LogInButton {
        color: $menuColor;
    }

    .dropdown-menu a,
    .dropdown-menu .title,
    .dropdown-menu .menu-show-all {
        color: $menuColor;
    }

.mcm .dropdown.mcm-fw .dropdown-menu {
    -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,.2);
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,2/10);
    border-top: none;
}

    .footer-container {
        background: $menuColor;
        color: white;
    }

    .copyright-container {
        background: $buttonColor;
        color: white;
        padding: 10px 0;
    }

    .copyright-container a {
        color: white;
    }

    a.cart-small-goto-checkout.btn.btn-primary {
        background: $buttonColor;
        border: none;
    }

    .mobilemenu-sm .cart-small-icon,
    .mobilemenu-md .cart-small-icon,
    .mobilemenu-lg .cart-small-icon {
        color: #3d3d3d;
    }

    .free-shipping-counter {
        background: $buttonColor;
    }
.HeaderSearch .search-btn {
    @include fromSizeSM() {
        display: block;
        margin: 0px;
        height: 40px;
        width: 40px;
        font-size: 25px;
        color: white;
    }
}

.HeaderSearch .icon-search {
    @include fromSizeSM() {
        background-color: #c1c2c0;
        height: 35px;
        width: 35px;
        float: none;
        border-radius: 20px;
        margin: 2px 0px 0px 1px;
        padding: 0;
        display: inline-block;


    }
}



    .ProductMenu a {
        color: $menuColor;
    }

    .ProductMenu .Level1Selected {
        background: $menuColor;
    }

    .ProductMenu .Level1Selected a {
        color: white;
    }

    .ProductMenu .Level2Selected {
        background-image: url(Images/menu-arrow.png);
        background-repeat: no-repeat;
        background-position: 30px center;

        @include toSizeMD {
            background-position-x: 6px;
        }
    }

    .ProductMenu .Level3Selected {
        background-image: url(Images/menu-arrow.png);
        background-repeat: no-repeat;
        background-position: 45px center;

        @include toSizeMD {
            background-position-x: 16px;
        }
    }
.mc-filter-left .ProductMenu .Level4, .mc-filter-left .ProductMenu .Level4Selected
{
    padding-left: 50px;
}


.ProductMenu .Level4Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 35px center;
    font-weight: bold;

    @include toSizeMD {
        background-position-x: 26px;
    }
}

    .section-menu {
        background: $menuColor;
    }

    .section-menu-row {
        background: $menuColor;
        border-top: 1px solid $buttonColor;
        border-bottom: 1px solid $buttonColor;
    }

    .section-menu-item:hover {
        border-top: 2px solid $buttonColor;
    }

    .ProductInfo .section-header span::after {
        background: $buttonColor;
    }

    .ProductInfo .current-price-container {
        color: #902926;
    }

    .BreadCrumb a:last-child {
        color: $buttonColor;
        font-weight: bold;
    }

    .Layout3Element .favorite-button,
    .Layout3Element .ad-buy-button {

        @include fromSizeSM() {
            background: transparent;
            background: $buttonColor;
            color: white;
        }
    }

    .Layout3Element .fav-remove-button {
        background: transparent;

        @include fromSizeSM() {
            background: $buttonTrashColor;
            color: #fff;
            border-left: 1px solid;
        }
    }

    @include toSizeSM() {
        .pub-favorites .Layout3Element .fav-remove-button {
            background: $buttonTrashColor;
            color: #fff;
            border-left: 1px solid;
        }

        .pub-favorites .Layout3Element .ad-buy-button {
            background: transparent;
            background: $buttonColor;
            color: white;
        }
    }

    .pub-favorites .Layout3Element .ad-buy-button,
    .pub-favorites .Layout3Element .fav-remove-button {
        @include toSizeSM() {
            display: block !important;
        }

        display: block;
        z-index: 1;
    }

    .pub-favorites .Layout3Element .favorite-button {
        @include toSizeSM() {
            display: none;
        }
    }

    .Layout3Element .AttributeNewIcon {
        background: $newAttributeBgColor;
    }

    .btn-favorite {
        font-weight: 300;
        font-family: $menuFont;
        background: $buttonDefaultColor;
        color: $buttonDefaultTextColor;

        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            background: lighten($buttonDefaultColor, 10%);
            outline: none;
        }
    }

    .favorites .btn .glyphicon {
        color: $buttonColor;
    }

    .remove-favorites-text {
        display: none;
    }


    .Layout3Element .ad-buy-button {
        border-right-color: white;
    }

    .buy-product,
    .scoll-config-summary {
        font-weight: 300;
        font-family: $menuFont;
        background: $buttonColor;
        color: white;
        border-radius: 20px;

        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            background: lighten($buttonColor, 10%);
            outline: none;
        }
    }


    .BuyButtonSmall.list-vbuy-btn,
    .TableList .VariantShowMore.list-vbuy-btn,
    .TableList .BuyButtonSmallDisabled.list-vbuy-btn,
    .TextBoxAntall {
        border-radius: 0px;
    }


    .TableList .BuyButtonSmall,
    .TableList .VariantShowMore {
        border-radius: 0px;
        background: $buttonColor;
        font-family: $menuFont;
        font-size: 18px;

        &:hover {
            background: darken($buttonColor, 10%);
        }
    }

    .TableList .BuyButtonSmall.list-vbuy-btn,
    .TableList .VariantShowMore.list-vbuy-btn {
        font-family: "Open Sans", sans-serif;
    }

    .TableList .BuyButtonSmallDisabled {
        border-radius: 0;
        font-family: $menuFont;
        font-size: 18px;
    }

    .PrdListCellsPagingTop a,
    .PrdListCellsPagingBottom a,
    .FieldPaging a {
        &:hover {
            background: $buttonColor;
        }
    }

    .PrdListCellsPagingTop a.SelectedPagingIndex,
    .PrdListCellsPagingBottom a.SelectedPagingIndex,
    .FieldPaging a.SelectedPagingIndex {
        background: $buttonColor;
    }

    .less-qty,
    .more-qty {
        border-radius: 50%;
        border-color: $menuColor;
        background: transparent;
        color: $menuColor;
        margin-top: 6px;

        &:hover {
            background: #eee;
        }
    }

    .less-qty {
        margin-right: 5px;
    }

    .more-qty {
        margin-left: 5px;
    }

    .product-info-container .QuantityTextBox {
        background: transparent;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        border: 1px solid $menuColor;
        color: $menuColor;
    }
    /* the checked style using the :checked pseudo class */
    .radio-inline input[type="radio"]:checked + span:before {
        background-color: $buttonColor;
    }

    .list-type-button {
        color: $menuColor;
    }

    .search-btn {
        /*color: $searchMobileColor;*/
        @include fromSizeSM {
            color: $searchColor;
            font-size: 28px;
        }
    }

    .search-btn-text {
        display: none;
    }

    .checkout,
    .checkout-left,
    .checkout-right {
        background-color: $backgroundCheckout;
    }

    .body-checkout .PageContainerBorder {
        @include toSizeLG {
            background-color: $backgroundCheckout;
        }
    }
    /*Nyhetsbrev*/
    .SubscribeButton.ui-btn {
        background: $buttonColor;
        font-family: $defaultFont;

        &:hover {
            background: lighten($buttonColor, 10%);
        }
    }


    /* Old forhandlerkart*/
.ForhandlerKart {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    min-height: 580px;
}



/* Css-triks for å bytte rekkefølge på html-noder */
/*.ProductMenuContainer {
    display: flex;
    flex-direction: column;
}
.publish-mode .ProductMenuContainer {
    display: inherit;
    flex-direction: initial;
}

.attribute-left-box.d2016 {
    order: 2;
}
.publish-mode .attribute-left-box.d2016 {
    order: initial;
}

.ProductMenu {
    order: 1;
    margin-top: -40px;
}

.publish-mode .ProductMenu {
    order: initial;
    margin-top: 80px;
}

.mc-filter-left .attribute-left-box .has-filters {
    margin-top: 0px;
}*/
/* rekkefølgetriks slutt */